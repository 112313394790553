<template>
  <v-container>
    <v-row>
      <v-col>
        <v-breadcrumbs :items="crumbs" divider="/"></v-breadcrumbs>
      </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row>
      <v-col class="col s12 m6">
        <v-card class="light-blue lighten-3 mb-2">
          <v-card-text class="white--black">
            Lorem ipsum dolor sit amet, consectetur adipisicing.
          </v-card-text>
        </v-card>
        <v-card class="yellow lighten-2 mb-2">
          <v-card-text class="white--black">
            Lorem ipsum dolor sit amet, consectetur adipisicing.
          </v-card-text>
        </v-card>
        <v-card class="green lighten-2 mb-2">
          <v-card-text class="white--text">
            Lorem ipsum dolor sit amet, consectetur adipisicing.
          </v-card-text>
        </v-card>
        <v-card class="brown lighten-2 mb-2">
          <v-card-text class="white--text">
            Lorem ipsum dolor sit amet, consectetur adipisicing.
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      offerId: ""
    };
  },
  methods: {},
  computed: {
    crumbs() {
      return [
        {
          text: "Архив заявок",
          disabled: false,
          to: "/archive"
        },
        {
          text: `Заявка id-${this.offerId}`,
          disabled: true
        }
      ];
    }
  },
  mounted() {
    this.offerId = this.$route.params.id;
  }
};
</script>
